<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="relyCode"
          label="リライコード"
          prepend-icon="mdi-text"
          outlined
          maxlength="15"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-autocomplete
          v-model="selectIncode"
          :items="incodes"
          label="事業所名"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-radio-group
          v-model="selectIsValid"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsIsValid.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsIsValid.Default"></v-radio>
        </v-radio-group>
      </template>

      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import RelyToShozoku from "@/models/RelyToShozoku";
import { BUSYO_CODE as BusyoCode } from "@/defines";

const SelectsIsValid = {
  Delete: 0,
  Default: 1
};

export default {
  name: "RelyToShozokuDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      relyCode: "",
      incodes: [],
      selectIncode: { code: "", name: "" },
      sort: null,
      selectIsValid: SelectsIsValid.Default,
      SelectsIsValid
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        const param = new RelyToShozoku(
          this.relyCode,
          this.selectIncode.code,
          this.selectIsValid
        );
        if (this.add) {
          await this.$post(this.Paths.relyToShozoku, param);
          this.add = false;
        } else {
          await this.$put(this.Paths.relyToShozoku, param);
        }
        this.$info("更新しました。");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/rely-to-shozoku";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShozokus() {
      console.log("getShozokus");
      const param = {
        code1: BusyoCode.WELFARE
      };
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.shozoku, "query=" + encode);
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;

    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.incodes = await this.getShozokus();

      if (!this.add) {
        this.relyCode = this.args.relyCode;

        this.incodes = this.incodes.filter(
          e => e.code === String(this.args.incode)
        );

        if (this.incodes.length > 0) {
          this.selectIncode.code = this.incodes[0].code;
          this.selectIncode.name = this.incodes[0].name;
        }

        this.selectIsValid = SelectsIsValid.Delete;
        if (String(this.args.isValid) === "1") {
          this.selectIsValid = SelectsIsValid.Default;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
