import { render, staticRenderFns } from "./RelyToShozokuDetails.vue?vue&type=template&id=e3e6b302&"
import script from "./RelyToShozokuDetails.vue?vue&type=script&lang=js&"
export * from "./RelyToShozokuDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports